<template>
  <div class="about-container">
    <div class="bg-shape" />
    <h1 class="about-title">About <span>Me</span></h1>
    <hr />
    <ExpansionPanel />
  </div>
</template>

<script>
import ExpansionPanel from "@/components/About/ExpansionPanel";

export default {
  name: "About",

  components: {
    ExpansionPanel
  }
};
</script>

<style scoped lang="scss">
.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center safe;
  align-items: stretch;
  padding-bottom: 10vh;
}

.bg-shape {
  background-color: var(--v-background-base);
  border-left: 50vw solid var(--v-background2-base);
  border-right: 50vw solid var(--v-background2-base);
  border-top: 8vh solid var(--v-background-base);
}

.about-title {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  margin-top: 8vh;
}

.about-title > span {
  color: var(--v-red-base);
}

hr {
  margin: 0 15vw 10vh;
}

@media screen and (max-width: 45rem) {
  .about-container {
    padding-bottom: 8vh;
  }

  .about-title {
    font-size: 3rem;
    text-align: left;
    margin-left: 1.5rem;
  }

  hr {
    margin: 0 1.5rem 1rem;
  }
}
</style>

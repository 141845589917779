var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-cards"},_vm._l((_vm.cards),function(card){return _c('div',{key:card.title,staticClass:"card"},[_c('v-card',{attrs:{"color":"background2","loading":_vm.loading,"flat":"","tile":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('v-img',{staticClass:"card-image",attrs:{"src":card.src}}),_c('v-card-actions',_vm._l((card.technologies),function(technology){return _c('v-tooltip',{key:technology.file,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pr-2",attrs:{"color":_vm.colors[technology.file]}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.files[technology.file])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(technology.file))])])}),1),_c('v-card-actions',[_c('v-dialog',{attrs:{"width":card.width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"card-button",attrs:{"tile":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(card.title)+" ")])]}}],null,true),model:{value:(card.dialog),callback:function ($$v) {_vm.$set(card, "dialog", $$v)},expression:"card.dialog"}},[_c('v-card',{attrs:{"color":card.primaryColor}},[(card.images.length <= 1)?_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((card.images),function(image){return _c('v-carousel-item',{key:image.src,attrs:{"eager":""}},[_c('v-img',{attrs:{"src":image.src,"height":"100%","eager":""}},[_c('v-card-actions',[(card.href)?_c('v-btn',_vm._g(_vm._b({staticClass:"dialog-visit",attrs:{"text":"","href":card.href,"target":"_blank"}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Visit ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":card.secondaryColor,"large":"","icon":""},on:{"click":function($event){card.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)}),1):_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows-on-hover":"","height":"auto"},model:{value:(_vm.carousel),callback:function ($$v) {_vm.carousel=$$v},expression:"carousel"}},_vm._l((card.images),function(image){return _c('v-carousel-item',{key:image.src,attrs:{"eager":""}},[_c('v-img',{attrs:{"src":image.src,"height":"100%","eager":""}},[_c('v-card-actions',[(card.href)?_c('v-btn',_vm._g(_vm._b({staticClass:"dialog-visit",attrs:{"text":"","href":card.href,"target":"_blank"}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" Visit ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":card.secondaryColor,"large":"","icon":""},on:{"click":function($event){card.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)}),1),_c('v-card',{attrs:{"color":card.primaryColor}},[_c('v-card-text',{style:({
                  fontFamily: card.fontFamily,
                  fontSize: '1.1rem',
                  color: card.fontColor
                })},[_vm._v(" "+_vm._s(card.description)+" ")])],1)],1)],1),_c('v-spacer'),(card.git)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"card-git",attrs:{"icon":"","text":"","href":card.git,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#F1502F"}},[_vm._v("mdi-git")])],1)]}}],null,true)},[_c('span',[_vm._v("Project Repository")])]):_vm._e()],1)],2)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="contact-container">
    <div class="bg-shape" />
    <h1 class="contact-title">Contact <span>Me</span></h1>
    <hr />
    <div class="contact-form">
      <v-card class="form" flat tile>
        <v-form
          name="contact"
          method="POST"
          data-netlify="true"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input type="hidden" name="form-name" value="contact" />
          <v-text-field
            type="text"
            name="name"
            v-model="name"
            :rules="nameRules"
            prepend-inner-icon="fas fa-user"
            label="Name"
            required
          >
          </v-text-field>
          <v-text-field
            type="email"
            name="email"
            v-model="email"
            :rules="emailRules"
            prepend-inner-icon="fas fa-at"
            label="Email"
            required
          >
          </v-text-field>
          <v-textarea
            name="message"
            v-model="message"
            :rules="messageRules"
            :counter="500"
            prepend-inner-icon="fas fa-pencil-alt"
            label="Your meassage"
            required
            rows="10"
            no-resize
          >
          </v-textarea>
          <v-btn
            type="submit"
            class="mr-4 red--text"
            :disabled="!valid"
            color="foreground"
            tile
            elevation="1"
            @click="submit"
          >
            submit
          </v-btn>
          <v-btn
            text
            class="mr-4"
            color="text"
            tile
            elevation="0"
            @click="reset"
          >
            clear
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",

  data: () => ({
    valid: true,
    name: "",
    nameRules: [v => !!v || "Name is required."],
    email: "",
    emailRules: [
      v => {
        if (v.length > 0) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Email must be valid.";
        }
      }
    ],
    message: "",
    messageRules: [v => v.length <= 500 || "Too many characters."]
  }),

  methods: {
    submit() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-container {
  display: flex;
  min-height: 85vh;
  flex-direction: column;
  justify-content: center safe;
  align-items: stretch;
}

.bg-shape {
  background-color: var(--v-background-base);
  border-left: 50vw solid var(--v-background2-base);
  border-right: 50vw solid var(--v-background2-base);
  border-top: 8vh solid var(--v-background-base);
}

.contact-title {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  margin-top: 8vh;
}

.contact-title > span {
  color: var(--v-red-base);
}

hr {
  margin: 0 15vw 1rem;
}

.contact-form {
  margin: auto 15vw;
  padding-bottom: 2rem;
}

.form {
  padding: 1rem;
}

@media screen and (max-width: 45rem) {
  .contact-container {
    min-height: 100vh;
  }

  .contact-title {
    font-size: 3rem;
    text-align: left;
    margin-left: 1.5rem;
  }

  hr {
    margin: 0 1.5rem 1rem;
  }

  .contact-form {
    max-width: 39rem;
    margin: 0 1.5rem 1rem;
  }
}
</style>

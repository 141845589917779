<template>
  <div class="projects-container">
    <div class="bg-shape" />
    <h1 class="projects-title">My <span>Projects</span></h1>
    <hr />
    <div class="projects-view">
      <!--<ProjectsFilter />-->
      <ProjectsCards />
      <h2 class="keyline">
        <span>GitHub <span>Activity</span></span>
      </h2>
      <Activity />
    </div>
  </div>
</template>

<script>
//import ProjectsFilter from "./ProjectsFilter";
import ProjectsCards from "./ProjectsCards";
import Activity from "./Activity";

export default {
  name: "Projects",

  components: {
    //ProjectsFilter,
    ProjectsCards,
    Activity
  }
};
</script>

<style lang="scss" scoped>
.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center safe;
  align-items: stretch;
  padding-bottom: 10vh;
}

.bg-shape {
  background-color: var(--v-background2-base);
  border-left: 50vw solid var(--v-background-base);
  border-right: 50vw solid var(--v-background-base);
  border-top: 8vh solid var(--v-background2-base);
}

.projects-title {
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  margin-top: 8vh;
}

.projects-title > span {
  color: var(--v-red-base);
}

hr {
  margin: 0 15vw 10vh;
}

.projects-view {
  margin: 0 15vw;
}

.projects-view .keyline {
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 1rem;
  align-items: center;
  width: 100%;
}

.projects-view .keyline span > span {
  color: var(--v-red-base);
}

.projects-view .keyline::before,
.projects-view .keyline::after {
  content: "";
  height: 1px;
  background: var(--v-foreground-base);
}

@media screen and (max-width: 45rem) {
  .projects-container {
    padding-bottom: 0;
  }

  .projects-title {
    font-size: 3rem;
    text-align: left;
    margin-left: 1.5rem;
  }

  hr {
    margin: 0 1.5rem 1rem;
  }

  .projects-view {
    max-width: 39rem;
    margin: 0 1.5rem 1rem;
  }
}
</style>

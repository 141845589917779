<template>
  <v-app-bar app flat tile height="40rem" width="2.5rem" hide-on-scroll>
    <div class="theme-switch">
      <v-btn
        height="2.5rem"
        width="2.5rem"
        icon
        :class="{ 'theme--dark': darkMode, 'theme--light': !darkMode }"
        @click="toggle_theme"
      >
        <v-icon>fas fa-lightbulb</v-icon>
      </v-btn>
    </div>
    <v-banner
      class="banner"
      v-model="themeInfo"
      color="background2"
      single-line
    >
      Default theme is set by your operating system preferences
      <template v-slot:actions="{ dismiss }">
        <v-btn icon color="red" height="2.5rem" width="2.5rem" @click="dismiss">
          <v-icon color="red" small>far fa-times-circle</v-icon>
        </v-btn>
      </template>
    </v-banner>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  data: () => ({
    themeInfo: true
  }),
  methods: {
    toggle_theme: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-switch {
  margin-left: -1rem;
}

.theme--light {
  color: var(--v-red-base) !important;
}

.theme--dark {
  color: var(--v-white-base) !important;
}

.v-banner {
  ::v-deep .v-banner__wrapper {
    border-left: 2px var(--v-red-base) solid;
    border-bottom: none !important;
    padding: 0 0 0 1rem !important;

    .v-banner__actions {
      height: 2.5rem !important;
      margin: 0 !important;

      .v-btn {
        margin: 0 !important;
      }
    }

    .v-banner__text {
      color: var(--v-text-base);
    }
  }
}

@media screen and (max-width: 45rem) {
  .v-banner {
    ::v-deep .v-banner__wrapper {
      .v-banner__text {
        font-size: calc(0.25rem + 1.5vw);
      }
    }
  }
}
</style>

<template>
  <v-app>
    <AppBar />
    <v-main>
      <section class="n1" id="title">
        <Title />
      </section>
      <section class="n2" id="about">
        <About />
      </section>
      <section class="n1" id="projects">
        <Projects />
      </section>
      <section class="n2" id="contact">
        <Contact />
      </section>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar/AppBar";
import Title from "./components/Title/Title";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

export default {
  name: "App",

  components: {
    AppBar,
    Title,
    About,
    Projects,
    Contact,
    Footer
  }
};
</script>

<style lang="scss">
* {
  border-radius: 0 !important;
}

html,
body {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  font-size: 16px;
  z-index: 0;
  font-family: "Source Sans Pro", sans-serif;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}

section.n1 {
  background-color: var(--v-background-base);
}

section.n2 {
  background-color: var(--v-background2-base);
}

@media screen and (min-width: 600px) {
  body {
    font-size: 16px;
  }
}
</style>

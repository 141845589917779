<template>
  <div class="about-expansion">
    <v-expansion-panels flat tile accordion multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="expansion-header">Profile</span>
          <template v-slot:actions>
            <v-icon color="red" size="large" left> fas fa-chevron-down </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-content">
          <CV />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="expansion-header">Experience</span>
          <template v-slot:actions>
            <v-icon color="red" size="large" left> fas fa-chevron-down </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-content">
          <Skills />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="expansion-header">Education</span>
          <template v-slot:actions>
            <v-icon color="red" size="large" left> fas fa-chevron-down </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-content">
          <v-timeline
            class="timeline-container"
            v-for="(education, ed) in educations"
            :key="ed"
            dense
          >
            <v-timeline-item color="red" fill-dot>
              <template v-slot:icon>
                <v-icon color="text">
                  {{ education.icon }}
                </v-icon>
              </template>
              <v-card tile elevation="1" color="foreground2">
                <v-card-title class="timeline-place">
                  <span>{{ education.place }}</span>
                </v-card-title>
                <v-card-subtitle class="timeline-year">
                  <span>{{ education.year }}</span>
                </v-card-subtitle>

                <v-card-subtitle class="timeline-content">
                  <span>{{ education.content }} </span>
                  <v-btn
                    icon
                    class="timeline-pdf"
                    :href="education.pdf"
                    target="_blank"
                    v-if="education.pdf"
                  >
                    <v-icon small color="red">fas fa-file-pdf</v-icon>
                  </v-btn>
                </v-card-subtitle>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CV from "@/components/About/CV";
import Skills from "@/components/About/Skills";

export default {
  name: "ExpansionPanel",
  components: {
    CV,
    Skills
  },

  data: () => ({
    panel: [0],
    educations: [
      {
        year: "2018 - 2020",
        place: "Leeds Beckett University",
        content: "Master of Science - Sport & Exercise Nutrition",
        icon: "fas fa-graduation-cap",
        pdf:
          "https://drive.google.com/file/d/15nmkuPEFOlOfl34QZMhNvHCQS-OkyDdq/view?usp=sharing"
      },
      {
        year: "2013 - 2017",
        place: "Bangor University",
        content: "Bachelor of Science - Chemistry (Hons)",
        icon: "fas fa-university",
        pdf:
          "https://drive.google.com/file/d/1H4uYWSD5LUuPyebn34pHRb_CoRBRfMsH/view?usp=sharing"
      },
      {
        year: "2011 - 2013",
        place: "Brooksbank Sixth Form College",
        content: "A-Levels - Chemistry, Physics, Geography",
        icon: "fas fa-school"
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.about-expansion {
  margin: 0 15vw 1rem;
}

.expansion-header {
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  text-decoration: underline;
  text-decoration-color: var(--v-red-base);
}

.expansion-content {
  font-family: "Source Sans Pro", sans-serif;
  margin-right: 3rem;
}

.timeline-place {
  padding: 0.5rem 0.8rem 0.8rem;
}

.timeline-place > span {
  color: var(--v-text-base);
  font-family: "Playfair Display", serif;
  font-weight: 600;
}

.timeline-year {
  padding: 0.3rem 0.8rem;
}

.timeline-year > span {
  color: var(--v-red-base);
  font-weight: 600;
  font-size: 1.1rem;
}

.timeline-content {
  padding: 0 0.8rem 0.4rem;
}

.timeline-content > span {
  color: var(--v-text-base);
  font-weight: 600;
  font-size: 1.1rem;
}

@media screen and (max-width: 45rem) {
  .about-expansion {
    max-width: 39rem;
    margin: 0 1.5rem 1rem;
  }

  .expansion-content {
    margin-right: 0;
  }

  .timeline-container {
    margin: 0 0 0 -1.8rem;
  }
}
</style>

<template>
  <div class="experience-skills">
    <v-card tile flat>
      <v-sheet tile>
        <v-text-field
          class="search"
          v-model="search"
          label="Search the tools I've used"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </v-sheet>
      <v-card-text>
        <v-treeview
          :items="items"
          :search="search"
          :filter="filter"
          :open.sync="open"
          expand-icon="mdi-chevron-down red--text"
          dense
          hoverable
          open-on-click
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.file">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
            <v-icon v-else :color="colors[item.file]">
              {{ files[item.file] }}
            </v-icon>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Skills",

  data: () => ({
    colors: {
      python: "#306998",
      django: "#0f5638",
      flask: "text",
      go: "#00add8",
      javascript: "#F0DB4F",
      vue: "#41b883",
      vuetify: "#2196F3",
      node: "#3C873A",
      dart: "#02569b",
      flutter: "#0175c2",
      html: "#E44D26",
      css: "#3C99DC",
      sass: "#CD6799",
      markdown: "text",
      linux: "text",
      docker: "#1396c3",
      git: "#F1502F",
      github: "text",
      gitlab: "#FC6D27",
      postgresql: "#336791",
      sqlite: "#00758F",
      firebase: "#FFA611"
    },
    files: {
      python: "mdi-language-python",
      django: "mdi-language-python",
      flask: "mdi-language-python",
      go: "mdi-language-go",
      javascript: "mdi-language-javascript",
      vue: "mdi-vuejs",
      vuetify: "mdi-vuetify",
      node: "mdi-nodejs",
      dart: "mdi-bird",
      flutter: "mdi-bird",
      html: "mdi-language-html5",
      css: "mdi-language-css3",
      sass: "mdi-sass",
      markdown: "mdi-language-markdown",
      linux: "mdi-linux",
      docker: "mdi-docker",
      git: "mdi-git",
      github: "mdi-github",
      gitlab: "mdi-gitlab",
      postgresql: "mdi-database",
      sqlite: "mdi-database",
      firebase: "mdi-firebase"
    },
    items: [
      {
        id: 1,
        name: "Languages",
        children: [
          {
            id: 101,
            name: "Dart",
            file: "dart",
            children: [
              {
                id: 10101,
                name: "Flutter",
                file: "flutter"
              }
            ]
          },
          {
            id: 102,
            name: "Go",
            file: "go",
            children: []
          },
          {
            id: 103,
            name: "JavaScript",
            file: "javascript",
            children: [
              {
                id: 10302,
                name: "Node.js",
                file: "node"
              },
              {
                id: 10303,
                name: "Vue.js",
                file: "vue",
                children: [
                  {
                    id: 1030101,
                    name: "Vuetify",
                    file: "vuetify"
                  }
                ]
              }
            ]
          },
          {
            id: 104,
            name: "Python",
            file: "python",
            children: [
              {
                id: 10401,
                name: "Django",
                file: "django"
              },
              {
                id: 10402,
                name: "Flask",
                file: "flask"
              }
            ]
          }
        ]
      },
      {
        id: 2,
        name: "Frontend",
        children: [
          {
            id: 201,
            name: "CSS",
            file: "css",
            children: [
              {
                id: 20101,
                name: "SASS",
                file: "sass"
              }
            ]
          },
          {
            id: 202,
            name: "HTML",
            file: "html"
          },
          {
            id: 203,
            name: "Markdown",
            file: "markdown"
          }
        ]
      },
      {
        id: 3,
        name: "Databases",
        children: [
          {
            id: 301,
            name: "Firebase",
            file: "firebase"
          },
          {
            id: 302,
            name: "PostgreSQL",
            file: "postgresql"
          },
          {
            id: 303,
            name: "SQLite",
            file: "sqlite"
          }
        ]
      },
      {
        id: 4,
        name: "DevOps",
        children: [
          {
            id: 401,
            name: "Docker",
            file: "docker"
          },
          {
            id: 402,
            name: "Git",
            file: "git"
          },
          {
            id: 403,
            name: "GitHub",
            file: "github"
          },
          {
            id: 404,
            name: "GitLab",
            file: "gitlab"
          },
          {
            id: 405,
            name: "Linux",
            file: "linux"
          }
        ]
      }
    ],
    search: null,
    caseSensitive: false
  }),

  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  border-radius: 0 !important;
}

.v-card__text {
  padding: 1rem 0 0;
}

@media screen and (max-width: 45rem) {
  .experience-skills {
    margin-bottom: 0;
  }
}
</style>

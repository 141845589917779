<template>
  <div class="projects-cards">
    <div class="card" v-for="card in cards" :key="card.title">
      <v-card color="background2" :loading="loading" flat tile>
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-img class="card-image" :src="card.src"> </v-img>
        <v-card-actions>
          <v-tooltip
            top
            v-for="technology in card.technologies"
            :key="technology.file"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="pr-2"
                :color="colors[technology.file]"
                v-bind="attrs"
                v-on="on"
              >
                {{ files[technology.file] }}
              </v-icon>
            </template>
            <span>{{ technology.file }}</span>
          </v-tooltip>
        </v-card-actions>
        <v-card-actions>
          <v-dialog v-model="card.dialog" :width="card.width">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="card-button" tile text v-bind="attrs" v-on="on">
                {{ card.title }}
              </v-btn>
            </template>

            <v-card :color="card.primaryColor">
              <v-carousel
                v-if="card.images.length <= 1"
                v-model="carousel"
                hide-delimiters
                :show-arrows="false"
                height="auto"
              >
                <v-carousel-item
                  v-for="image in card.images"
                  :key="image.src"
                  eager
                >
                  <v-img :src="image.src" height="100%" eager>
                    <v-card-actions>
                      <v-btn
                        v-if="card.href"
                        class="dialog-visit"
                        text
                        :href="card.href"
                        target="_blank"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Visit
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :color="card.secondaryColor"
                        large
                        icon
                        @click="card.dialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-img>
                </v-carousel-item>
              </v-carousel>
              <v-carousel
                v-else
                v-model="carousel"
                hide-delimiters
                show-arrows-on-hover
                height="auto"
              >
                <v-carousel-item
                  v-for="image in card.images"
                  :key="image.src"
                  eager
                >
                  <v-img :src="image.src" height="100%" eager>
                    <v-card-actions>
                      <v-btn
                        v-if="card.href"
                        class="dialog-visit"
                        text
                        :href="card.href"
                        target="_blank"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Visit
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :color="card.secondaryColor"
                        large
                        icon
                        @click="card.dialog = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-img>
                </v-carousel-item>
              </v-carousel>
              <v-card :color="card.primaryColor">
                <v-card-text
                  v-bind:style="{
                    fontFamily: card.fontFamily,
                    fontSize: '1.1rem',
                    color: card.fontColor
                  }"
                >
                  {{ card.description }}
                </v-card-text>
              </v-card>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="card.git">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="card-git"
                text
                :href="card.git"
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#F1502F">mdi-git</v-icon>
              </v-btn>
            </template>
            <span>Project Repository</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsCards",

  data: () => ({
    loading: false,
    show: false,
    colors: {
      Python: "#306998",
      Django: "#0f5638",
      Flask: "text",
      Go: "#00add8",
      JavaScript: "#F0DB4F",
      Vue: "#41b883",
      Vuetify: "#2196F3",
      Node: "#3C873A",
      Dart: "#02569b",
      Flutter: "#0175c2",
      HTML: "#E44D26",
      CSS: "#3C99DC",
      SASS: "#CD6799",
      Markdown: "text",
      Linux: "text",
      Docker: "#1396c3",
      Git: "#F1502F",
      PostgreSQL: "#336791",
      SQLite: "#00758F",
      Firebase: "#FFA611"
    },
    files: {
      Python: "mdi-language-python",
      Django: "mdi-language-python",
      Flask: "mdi-language-python",
      Go: "mdi-language-go",
      JavaScript: "mdi-language-javascript",
      Vue: "mdi-vuejs",
      Vuetify: "mdi-vuetify",
      Node: "mdi-nodejs",
      Dart: "mdi-bird",
      Flutter: "mdi-bird",
      HTML: "mdi-language-html5",
      CSS: "mdi-language-css3",
      SASS: "mdi-sass",
      Markdown: "mdi-language-markdown",
      Linux: "mdi-linux",
      Docker: "mdi-docker",
      Git: "mdi-git",
      PostgreSQL: "mdi-database",
      SQLite: "mdi-database",
      Firebase: "mdi-firebase"
    },
    cards: [
      {
        dialog: false,
        title: "GoDo",
        git: "https://www.github.com/rsHalford/godo",
        href: "https://todo.xhalford.com",
        src: require("@/assets/projects/godo_simple_logo.svg"),
        primaryColor: "#bfe1f1",
        secondaryColor: "#00385c",
        fontFamily: "Roboto",
        fontColor: "#00385c",
        description:
          "GoDo aims to help you get organised with your tasks. Designed to be simple and accessible.",
        technologies: [
          { file: "Go" },
          { file: "JavaScript" },
          { file: "PostgreSQL" },
          { file: "Linux" },
          { file: "Docker" },
          { file: "HTML" },
          { file: "CSS" }
        ],
        images: [
          { src: require("@/assets/projects/godo_logo.svg") },
          { src: require("@/assets/projects/godo_simple_logo.svg") }
        ],
        width: "700"
      },
      {
        dialog: false,
        title: "Blog",
        href: "https://www.xhalford.com",
        git: "https://www.github.com/rsHalford/xhalford-blog",
        src: require("@/assets/projects/xhalford.svg"),
        primaryColor: "#1d2021",
        secondaryColor: "#cc241d",
        fontFamily: "Roboto",
        fontColor: "#ffffff",
        description:
          "Personal blog used to create and publish informational and tutorial articles.",
        technologies: [
          { file: "Python" },
          { file: "Django" },
          { file: "JavaScript" },
          { file: "HTML" },
          { file: "CSS" },
          { file: "Markdown" },
          { file: "Linux" },
          { file: "PostgreSQL" }
        ],
        images: [{ src: require("@/assets/projects/xhalford.svg") }],
        width: "700"
      },
      {
        dialog: false,
        title: "FoodFacts",
        git: "https://www.github.com/rsHalford/flutter-food-facts",
        src: require("@/assets/projects/foodfacts.svg"),
        primaryColor: "#64b5f6",
        secondaryColor: "#0D47A1",
        fontFamily: "Roboto",
        fontColor: "#ffffff",
        description:
          "Barcode scanner app to find the nutritional details of food products, using the openfoodfacts.org API.",
        technologies: [
          { file: "Dart" },
          { file: "Flutter" },
          { file: "Firebase" }
        ],
        images: [
          { src: require("@/assets/projects/foodfacts-mockup.png") },
          { src: require("@/assets/projects/foodfacts.svg") }
        ],
        width: "700"
      },
      {
        dialog: false,
        title: "Portfolio",
        href: "https://www.richard-halford.com",
        src: require("@/assets/projects/richard-halford.svg"),
        primaryColor: "#202020",
        secondaryColor: "#B71C1C",
        fontFamily: "Playfair Display",
        fontColor: "#ffffff",
        description: "Personal portfolio website, built using Vue.js.",
        technologies: [
          { file: "JavaScript" },
          { file: "Vue" },
          { file: "Vuetify" },
          { file: "Node" },
          { file: "HTML" },
          { file: "CSS" },
          { file: "SASS" }
        ],
        images: [{ src: require("@/assets/projects/richard-halford.svg") }],
        width: "700"
      },
      {
        dialog: false,
        title: "Simply Weather",
        git: "https://www.github.com/rsHalford/flutter-simply-weather",
        src: require("@/assets/projects/simply-weather.svg"),
        primaryColor: "#0080e1",
        secondaryColor: "#000080",
        fontFamily: "Noto Sans",
        fontColor: "#ffffff",
        description:
          "Designed to be a very simple and straight to the point weather app built with Flutter, and utilising the OpenWeather API.",
        technologies: [{ file: "Dart" }, { file: "Flutter" }],
        images: [
          { src: require("@/assets/projects/simply-weather-mockup.png") },
          { src: require("@/assets/projects/simply-weather.svg") }
        ],
        width: "700"
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.projects-cards {
  max-width: 84rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
  grid-gap: 1rem;
  margin-bottom: 10vh;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.card-image {
  height: 15.625rem;
}

.card-button {
  color: var(--v-text-base);
  font-weight: 700;
  font-size: 1.3rem;
  text-transform: none;
}

.dialog-visit {
  margin-left: auto;
}

@media screen and (max-width: 45rem) {
  .projects-cards {
    margin-bottom: 4rem;
  }
}
</style>

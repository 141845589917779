<template>
  <v-footer class="background" app inset absolute padless>
    <v-row no-gutters>
      <v-col>
        <v-row class="scroll" justify="center">
          <v-btn
            height="2.5rem"
            color="red"
            @click="scrollUp"
            elevation="1"
            tile
          >
            <v-icon>fas fa-chevron-up</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-btn
            class="social-icon"
            icon
            v-for="social in socials"
            :key="social.title"
            :href="social.href"
            target="_blank"
          >
            <v-icon :color="social.color" large>{{ social.icon }}</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-col class="py-2 text-center" cols="12">
            <strong>Richard Halford</strong> — &copy;
            {{ new Date().getFullYear() }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({
    socials: [
      {
        title: "Twitter",
        href: "https://www.twitter.com/rshalford_",
        color: "#55acee",
        icon: "fab fa-twitter"
      },
      {
        title: "LinkedIn",
        href: "https://www.linkedin.com/in/richard-halford/",
        color: "#0077b5",
        icon: "fab fa-linkedin"
      },
      {
        title: "GitHub",
        href: "https://www.github.com/rsHalford",
        color: "",
        icon: "fab fa-github"
      },
      {
        title: "GitLab",
        href: "https://www.gitlab.com/rsHalford",
        color: "#FC6D27",
        icon: "fab fa-gitlab"
      }
    ]
  }),

  methods: {
    scrollUp() {
      window.scrollTo({
        top: 0,
        left: 0
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll {
  margin-top: -1.2rem;
}

.social-icon {
  margin: 2rem 1.5rem 1.5rem;
}
</style>

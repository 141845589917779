<template>
  <div class="title-container">
    <div class="center-welcome">
      <h1 class="welcome-title">
        Hi, I'm
        <span class="name red--text">Richard Halford</span>.
      </h1>
      <hr />
      <!-- TODO: Animate a scrolling list of subtitles -->
      <h2 class="welcome-subtitle">I'm a full-stack developer.</h2>
    </div>
    <div class="button-container">
      <v-btn
        class="scroll-button"
        height="2.5rem"
        color="red"
        @click="scrollDown"
        elevation="1"
        tile
      >
        Contact Me
        <v-icon right>fas fa-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",

  methods: {
    scrollDown() {
      document.getElementById("contact").scrollIntoView();
    }
  }
};
</script>

<style scoped lang="scss">
.title-container {
  min-height: 86vh;
  position: relative;
}

.center-welcome {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  transform: translate(0%, -50%);
}

.welcome-title {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-size: 4rem;
  line-height: 1.3;
  text-align: center;
}

.name {
  font-weight: 500;
}

hr {
  margin: 1.5rem 0 1.3rem;
}

.welcome-subtitle {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 3rem;
  line-height: 1.3;
  text-align: center;
}

.button-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20%;
  transform: translate(0%, -50%);
}

.scroll-button {
  display: block;
  margin: 0 auto;
  .v-btn__content {
    .v-icon {
      transition: 0.3s ease-in-out;
    }
  }
}

.scroll-button:hover {
  .v-btn__content {
    .v-icon {
      transform: rotate(90deg);
    }
  }
}

@media screen and (max-width: 45rem) {
  .center-welcome {
    left: 40%;
    top: 40%;
    transform: translate(-40%, -50%);
  }

  .welcome-title {
    font-size: 3rem;
    text-align: left;
  }

  .welcome-subtitle {
    font-size: 2.2rem;
    text-align: left;
  }

  .button-container {
    left: 40%;
    bottom: 15%;
    transform: translate(-40%, -50%);
  }

  .scroll-button {
    margin: 0;
  }
}
</style>

<template>
  <div class="activity-container">
    <div class="calendar">
      <template slot="progress">
        <v-progress-linear
          color="red"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <!-- Loading Git Contributions -->
      Loading the data just for you.
    </div>
  </div>
</template>

<script>
import GitHubCalendar from "github-calendar";

export default {
  name: "Activity",

  mounted() {
    GitHubCalendar(".calendar", "rsHalford", {
      summary_text: " ",
      responsive: true,
      tooltips: false
    });
  }
};
</script>

<style lang="scss" scoped>
@import "https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css";

.activity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  margin-bottom: 3rem;
}

.calendar {
  min-width: 50vw;
  max-width: 84rem;
  background-color: var(--v-background-base);
  border: none;
  margin: 1rem;
}
</style>

import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: {
        text: "#ffffff",
        background: "#121212",
        background2: "#181818",
        foreground: "#303030",
        foreground2: "#303030",
        red: "#B71C1C"
      },
      light: {
        text: "#000000",
        background: "#ffffff",
        background2: "#efefef",
        foreground: "#cccccc",
        foreground2: "#f7f7f7",
        red: "#D32F2F"
      }
    }
  }
});

export default vuetify;

<template>
  <div class="cv-container">
    <div class="img-container">
      <img src="@/assets/profile/graduation.webp" />
    </div>
    <div class="text-container">
      <h1 class="text-headline">Self-Taught Full-Stack Developer</h1>
      <p>
        A self-taught software developer who is interested in using Python and
        Go for developing applications across all platforms.
      </p>
      <p>
        With experience scripting in Bash; creating apps with Flutter; designing
        frontends with JavaScript. And always being open to learning new
        languages and development tools.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CV"
};
</script>

<style lang="scss" scoped>
.img-container {
  float: right;
  margin-left: 1rem;
  overflow: hidden;
}

.img-container h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.img-container a {
  text-decoration: none;
  color: var(--v-text-base);
}

.img-container a:hover {
  text-decoration: underline;
  text-decoration-color: var(--v-red-base);
}

.img-container > img {
  max-width: 300px;
}

.text-headline {
  border-bottom: 2px solid var(--v-background2-base);
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
}

@media screen and (max-width: 45rem) {
  .img-container {
    float: none;
    margin-left: 0;
  }

  .img-container h2 {
    text-align: left;
  }

  .img-container > img {
    max-width: 200px;
  }
}
</style>
